// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!./Fonts.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b95R0O6ILc85rYlO1WPd{width:55px;height:55px;border:none;display:inline-flex;align-items:center;justify-content:center;padding:0 0}.b95R0O6ILc85rYlO1WPd.kgkXfJMN1pBn5GDwKAiQ{width:120px;height:45px;justify-content:start}.b95R0O6ILc85rYlO1WPd.auFPe0ZDyYDP90hWHa6t{width:30px;height:30px}.b95R0O6ILc85rYlO1WPd.MKxtRa83RlF4LPUy2B33{width:25px;height:25px}.b95R0O6ILc85rYlO1WPd.K4N1g645ZLynf7rUfSyB{background-color:#E2E2E2;color:#333333}.b95R0O6ILc85rYlO1WPd.K4N1g645ZLynf7rUfSyB:hover,.b95R0O6ILc85rYlO1WPd.K4N1g645ZLynf7rUfSyB.lD5Ki9XODid4PAR8thSw{background-color:#FFFFFF}.b95R0O6ILc85rYlO1WPd.K4N1g645ZLynf7rUfSyB:disabled{background-color:#FFFFFF}.b95R0O6ILc85rYlO1WPd.y9P5FsHRKkfc_RVnfyko{background-color:#333333;color:#FFFFFF}.b95R0O6ILc85rYlO1WPd.y9P5FsHRKkfc_RVnfyko:hover,.b95R0O6ILc85rYlO1WPd.y9P5FsHRKkfc_RVnfyko.lD5Ki9XODid4PAR8thSw{background-color:#666666}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/Button.modules.scss"],"names":[],"mappings":"AACA,sBAEI,UAAA,CACA,WAAA,CACA,WAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,2CACI,WAAA,CACA,WAAA,CACA,qBAAA,CAEJ,2CACI,UAAA,CACA,WAAA,CAEJ,2CACI,UAAA,CACA,WAAA,CAEJ,2CACI,wBAAA,CACA,aAAA,CACA,iHAEI,wBAAA,CAEJ,oDACI,wBAAA,CAGR,2CACI,wBAAA,CACA,aAAA,CACA,iHAEI,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light-background": `#E2E2E2`,
	"light-active-background": `#FFFFFF`,
	"light-disabled-background": `#C8C8C8`,
	"light-color": `#FFFFFF`,
	"dark-background": `#333333`,
	"dark-active-background": `#666666`,
	"dark-color": `#333333`,
	"button": `b95R0O6ILc85rYlO1WPd ${___CSS_LOADER_ICSS_IMPORT_0___.locals["fontRegular"]}`,
	"wide": `kgkXfJMN1pBn5GDwKAiQ`,
	"small": `auFPe0ZDyYDP90hWHa6t`,
	"smaller": `MKxtRa83RlF4LPUy2B33`,
	"light": `K4N1g645ZLynf7rUfSyB`,
	"active": `lD5Ki9XODid4PAR8thSw`,
	"dark": `y9P5FsHRKkfc_RVnfyko`
};
export default ___CSS_LOADER_EXPORT___;
