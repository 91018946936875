// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bOUut2FWKU2aN_ahzdAI{font-family:"acumin-pro-condensed",sans-serif;font-size:12px;height:25px;padding-left:15px}.ZN_DCHJ9pa7dUkP3Eml9{width:100%;height:100%;border:unset}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/lines/OptionsLineComponent.modules.scss"],"names":[],"mappings":"AAAA,sBACI,6CAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsLine": `bOUut2FWKU2aN_ahzdAI`,
	"optionsSelect": `ZN_DCHJ9pa7dUkP3Eml9`
};
export default ___CSS_LOADER_EXPORT___;
